import React from "react"
import { ColorPalette } from "../Theme"

/** const */
const HeroBrandSection: React.FC = () => {
  return (
    <div>
      <img src="/brand/norhart-brand.png" alt="Norhart Apartments Brand" loading="eager" className="img-fluid w-100" />
    </div>
  )
}

/** export */
export default HeroBrandSection
