import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import HeroBrandSection from "../components/static-sections/HeroBrandSection"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.black

/** props */
interface Props {
  data?: any
  didSubmit?: boolean
}

/** const */
export const BrandPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/brand/",
          name: "Norhart Apartment Brand| Norhart",
          image: `${config.siteMetadata.siteUrl}/brand/brand-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Apartments Brand | Norhart"
      description="Norhart Apartments Showcase"
      keywords="norhart, apartments, showcase"
      imageTwitter={`${config.siteMetadata.siteUrl}/brand/norhart-brand-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/brand/norhart-brand-platform-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroBrandSection />

      <RibbonSection
        title="The Norhart Brand"
        tagLine="Apartments with Brand Recongnition"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartBlackCircleIcon}
        iconTitle="Norhart Apartments"
        title="Reconginze The Brand"
        subTitle={`"Insert Text"`}
        image=""
        imageAlt=""
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />
    </Layout>
  )
}

/** export */
/** export */
export default BrandPage
